.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ant-layout {
  background-color: #fff;
}
.custom-header {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Distribute space between logo and menu */
}

.logo-container {
  flex-shrink: 0; /* Prevent logo from shrinking */
  margin-top: 20px;
}

.logo {
  width: 30%;
  max-width: 400px;
  height: auto;
  object-fit: contain; /* Responsive image */
}

.menu-container {
  flex-grow: 1;
  display: flex;
  justify-content: end; 
}

.menu {
  display: flex; 
  justify-content: center;
  .ant-menu-item {
    width: 90px;
    .ant-menu-title-content {
      display: flex;
      justify-content: center;
    }
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .menu {
      flex-direction: column; 
      align-items: center;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.css */
/* App.css */
.custom-header {
  position: fixed; /* Fix the header at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white; /* White background */
 
}

.menu {
  display: flex;
  justify-content: center; /* Center the menu items */
  line-height: 64px; /* Align text vertically in the header */
}

.menu .ant-menu-item {
  color: black; /* Black text color */
}

/* .menu .ant-menu-item:hover {
  color: #1890ff; /* Optional: change color on hover */

.main-container {
  margin: auto;
  background-color: #fff;
  max-width: 100%;
  margin-top: 64px; /* Adjust based on the header height */
}

.maxwidth {
  max-width: 1080px;
  margin: 0 auto;
}

@media (max-width: 667px) {
    div[style*="margin-left: 150px"] {
        margin-left: 0 !important;
    }
}

.divider {
  border: 1px solid black;
}

.main-title {
 
  h1 {
    font-size: 64px;
    font-weight: 700;
    text-align: center;
    font-family: forum, serif;
  }
}
@media (min-width: 940px) {
  .main-title {
    width: 940px;
    margin: 33px 0px 16px calc((100% - 980px) * 0.5);
  }
}

.main-subtitle {
  margin-top:20px;
  text-align: center;
  font-size: 20px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.heading-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.main-p-head {
  max-width: 700px;
  line-height: 1.5;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #C2274F;
  font-size: 18px;
}

.home-p-section3 {
  background-color: #FFF0EC;
  padding: 20px;

  /* max-height: 300px;
  position: relative; */

}

.section3-container1 {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  margin: auto;
  position: relative;
}

.section3-container {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  margin: auto;
  position: relative;
  background-color: #FFF0EC;
}

.section3-image-container {
  flex: 1;
  max-width: 70%;
  height: 100%;
}

.section7-image-container {
  flex: 1;
  max-width: 100%;
  height: 100%;
  background-color: #66371b;
  position: relative;
  /* display: inline-block; */
}

.section7-responsive-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  border-radius: 15px;
  max-height: 200px;
  height: auto;
}

.section3-responsive-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  border-radius: 15px;
  max-height: 400px;
}
@media (max-width: 780px) {
  .responsive-image {
    height: 500px; /* Increase height for smaller screens */
  }
}


.section3-text-box {
  position: relative;
  /* top: 5%; */
  right: 16%;
  width: 50%;
  max-width: 400px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* border-radius: 20%; */
  /* margin-right:60px; */
}

.btn {

  display: inline-block;
  padding: 12px 20px;
  font-size: 12px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}
.packagebtn {

  display: inline-block;
  padding: 8px 20px;
  font-size: 12px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  /* height: ; */
  transition: background-color 0.3s ease, transform 0.2s;
}

.btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.btn:active {
  background-color: #004494;
  transform: translateY(1px);
}

.btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}

.tab-div {
  display: flex;
  justify-content: center;
  /* background-color: rgb(207, 231, 161); */
  min-height: 250px;
  max-height: 350px;
  /* border-left: #fff; */
}

/* .tab-div1 {

  border-left: #fff;
} */


@media (max-width: 600px) {
  .section3-text-box {
    margin-left: 300px !important;
  }
}

@media (min-width: 481px) {

  .section3-text-box {
    font-size: 14px;
    width: 90%;
    /* margin-left: 300px; */
    /* max-width: 200px; */
  }

  .section3-responsive-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: 15px;
    height: 500px;
  }

  .RC-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    height: 500px;
  }

}

@media (max-width: 800px) {
  .section3-responsive-image {
    display: none;
  }

  .overlay-text {
    font-size: 8px;
    /* Adjust font size for larger screens */
  }
}

@media (max-width: 600px) {

  .overlay-text {
    font-size: 12px !important;
    /* Adjust font size for larger screens */
    /* display: none; */
  }
}
@media (max-width: 480px) {
  .section3-container {
    display: flex; /* Use flex to center content */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    position: relative; /* Maintain relative positioning for child elements */
    padding: 20px; /* Add padding for breathing room */
  }

  .section3-image-container {
    display: none; /* Hide the image on small screens */
  }

  .section3-text-box {
    width: 90%; /* Maintain the desired width */
    max-width: 700px;
    background-color: rgba(255, 255, 255, 0.8); /* Background for readability */
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0; /* Add vertical margin to prevent overlap */
    text-align: center; /* Center text inside the box */
  }
}

.home-p-section3 {
  position: relative; /* Ensure the section is relative for absolute children */
  padding: 40px 0; /* Add some padding to the top and bottom */
}

.section3-text-box {
  margin-top: 10px; /* Ensure there's spacing */
}

/*  */

/* Media Queries for Responsiveness for image*/

@media (max-width: 768px) {

  .RC-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 300px;
  }

  .overlay-text {
    font-size: 18px;
    /* Adjust font size for larger screens */
  }

  .RC-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .logo {
    font-size: 20px;
  }

  .main-p-heading {
    font-size: 17px;
  }

  .main-p-text {
    font-size: 18px;
  }

  .main-p-text1 {
    font-size: 18px;
  }

  .ant-menu-horizontal {
    font-size: 14px;
  }

  .main-p-head {
    font-size: 18px;

  }
}

@media (max-width: 500px) {
  .main-p-text {
    font-size: 15px;
  }

  .main-p-text1 {
    font-size: 15px;
  }

  .main-p-heading {
    font-size: 12px;
  }

  .main-p-head {
    font-size: 14px;

  }
}

/* herobanner */
.herobanner {
  img {
    width: 100%
  }
  .about {
    display: flex;
    flex-direction: column;
    background-color: #EAD196;
    opacity: 0.7;
    padding: 30px;
    color: #7D0A0A;
    top: -20px;

    span {
      font-size: 32px;
      font-weight: bold;
      text-align: center;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
  }
}

/* Sectons */
.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
  span {
    font-family: sans-serif;
    font-size: 60px;
    color: rgb(194, 39, 79);
  }
}
.section-title.bottom-nil {
  margin-bottom: 10px;
}
.section-body {
  padding: 0 30px;
  text-align: center;
  font-size: 20px;
  line-height: 1.5em;
}

/* Packages */
.section-packages .packages:hover {
  cursor: pointer;
  .ant-card-head {
    background-color: lightsteelblue;
  }
}
.section-packages .packages {
  .ant-card-head {
    text-align: center;
    font-size: x-large;
  }
  .ant-card-body {
    .cost {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .services {
      .ant-row {
        display: flex;
        flex-direction: column;
      }
      .services_title {
        font-weight: bold;
      }
      .services_options {
        padding-left: 20px;
      }
    }
  }
}

/* Services */
.services {
  .home-p-sections {
    display: flex;
    margin: auto;
  }
  .home-p-sections:nth-child(odd) {
    .RC-div {
      flex-direction: row-reverse;
    }
  }
  
  .RC-div {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  
  .RC-img-div {
    flex: 1;
    max-width: 100%;
    margin-top: -2px;
  }
  
  .RC-txt-div {
    flex: 1;
    max-width: 100%;
    
    .main-p-heading1 {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      margin-top: 20px;
    }
    li {
      padding: 15px 5px;

      .label {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  
  .RC-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 400px;
  }
}

/* Gallery */
.swiper-slide img {
  display: block;
  width: 90%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
}

/* Quote */
.up-quote {
  margin-top: 20px;
  position: relative;
  text-align: center;
  img {
    width: 100%;
    object-fit: cover;
  }
  .quote {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: black; /* Change text color for better visibility */
    padding: 0 10px; /*  Add padding for better readability */
    max-width: 90%; /*  Ensure it doesn't overflow the image */
    white-space: normal; /*  Allow text to wrap */

    span {
      font-weight: bold;
      text-decoration: underline;
      margin: 0;
      text-align: center;
      font-size: 24px;
    }
  }
}

/* Projects */
.projects {
  .project-content {
    /* display: flex; flex-direction: column; */

    .project-wrapper {
      display: flex; 
      flex-wrap: wrap; 
      justify-content: space-between; 
      padding: 20px;

      .project {
        flex: 1 1 30%; 
        margin: 10px; 
        overflow: hidden; 
        position: relative;
      }
    }
  }
  img {
    height: 390px;
    transition: transform 0.9s ease,
  }
}

/* Contact Us */
.contactus {
  .contactus-wrapper {
    margin: 40px;
    display: flex;

    .contactus-logo {
      width: 30%;
      img {
        width: 100%;
      }
    }
    .contactus-form {
      width: 70%;
      text-align: center;
      .message {
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 20px;
      }
    }
  }
}


.mobile-page {
  display: none;
}

/* Show card layout on screens smaller than 960px */
@media (max-width: 960px) {
  .section-title {
    margin: 20px 0;
  }
  .section-packages .packages .ant-card-body {
    .services {
      display: flex;
      flex-direction: column;
      width: 160px;
      margin:auto;
      .ant-row {
        width: 160px;
        .services_title {
          font-weight: normal;
        }
        .services_options {
          display: none;
        }
      }
    }
    button {
      display: flex;
      margin-left: auto;
    }
  }

  .services {
    margin: 0 30px;
    .home-p-sections {
      margin-bottom: 40px !important;
      .RC-div {
        flex-direction: column !important;
      }
    }
  }

}

@media (max-width: 760px) {

  .herobanner .about {
    margin: 30px;
  }

  .web-page {
    display: none;
  }
  .mobile-page {
    display: block;
  }

  .projects .project-wrapper {
    flex-direction: column;
    .project {
      margin: 20px auto !important;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .contactus {
    .contactus-logo {
      display: none;
    }
    .contactus-form {
      width: 100% !important;
    }
  }
 
}